import DateHelper from '@/helpers/date.helper';

import LeadSource from '@/services/lead.source.service';

const service = new LeadSource();

export default {
    name: "LeadSources",

    data() {
        return {
            sources: [],
            source: {},
            editModal: false,
            errors: {},
            currentPage: 1,
            perPage: 25,
            totalRows: 0
        }
    },

    async mounted() {
        await this.list();
    },

    methods: {

        async list(page) {
            this.currentPage = page || 1;

            const skip = this.perPage * (this.currentPage - 1);

            const res = await service.list(this.perPage, skip);
            if (res && !res.error) {
                this.sources = res.data.sources;
                this.totalRows = res.data.count;
            }
        },

        async edit() {
            const res = await service.edit(this.source);
            if (res && !res.error) {
                const index = this.sources.findIndex(source => source._id === this.source._id);
                if (index > -1) {
                    this.sources[index] = this.source;
                }
                this.cancelModal();
            }
        },

        async openModal(data = {}, action = "edit") {
            this.source = {...data};

            if (action === 'edit') {
                this.editModal = true;
            }
        },

        cancelModal() {
            this.editModal = false;
            this.source= {};
            this.errors = {};
        },

        async setPerPage(num) {
            this.perPage = +(num);
            this.$hideModals();
            await this.list();
        },

        next() {
            this.list(this.currentPage + 1);
        },

        prev() {
            this.list(this.currentPage - 1);
        },
    },

    filters: {

        normalizeDate(date) {
            if (!date) return "N/A";
            return DateHelper.customFormat(date, "DD MMM YYYY h:mm a");
        },

    },

    watch: {
    }
};